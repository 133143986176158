body {
  margin: 0;
}

.container {
  margin: 10px;
  font-size: 20px;
}

.message {
  margin-top: 10px;
}

select {
  font-size: 20px;
}

fieldset {
  border: 0;
  padding: 0;
  margin-top: 10px;
}
